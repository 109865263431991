import { ConfigProvider } from "antd";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { brotliDecompress } from "zlib";
import { store, persistor } from "./common";
import MainRouter from "./features/_router/main.router";

export const colors = {
  blue: '#0D0584', //plava
  yellow: '#FFD400',  // žuta
  white: '#ffffff'  // bijela
}

const themeConfig = {
  token: {
    colorPrimary: colors.blue,
    colorTextBase: colors.blue,
    fontSize: 20,
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    colorBgLayout: colors.white,
  },
  components: {
    Button: {
      colorBgContainer: colors.yellow,
      paddingContentHorizontal: 80,
      controlHeight: 48,
      fontSize: 22,
      controlHeightLG: 48,
      controlHeightSM: 32,
      paddingContentHorizontalSM: 30,
      paddingXS: 30
    },
    Checkbox: {
      controlInteractiveSize: 20
    },
    Card: {
      boxShadowCard: "0 4px 10px rgba(0, 0, 0, 0.1)"
    },
    Input: {
      controlHeight: 54
    },
    Menu: {
      colorBgContainer: colors.white,
      colorItemBg: colors.blue,
      colorItemText: colors.white,
      colorItemTextHoverHorizontal: colors.white,
      colorItemTextHover: colors.white,
      colorItemTextSelected: colors.white,
      colorGroupTitle: colors.white,
      colorItemTextSelectedHorizontal: colors.white,
      colorItemBgSelected: colors.blue,
      fontSize: 22,
      controlHeightSM: 48,
      controlHeightLG: 60,
    },
    Typography: {
      sizeMarginHeadingVerticalStart: '0.5em',
      sizeMarginHeadingVerticalEnd: '0.5em',
      colorLink: colors.blue,
      colorLinkHover: colors.yellow
    },
    Breadcrumb: {
      fontSize: 22,
      fontSizeIcon: 22,
      colorText: colors.blue,
      colorTextDescription: colors.blue
    }
  }
}

const App = () => {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider
                theme={themeConfig}
            >
            <MainRouter></MainRouter>
          </ConfigProvider>
        </PersistGate>
      </Provider>
    );
  }
  
  export default App;