import HttpFactory from './abstract.api.service';
import { apiRoutes } from './routes';
import { Observable } from 'rxjs';
import { LoginInterface, LoginResponseInterface } from '../interfaces';

export class UserService {
  static login(data: LoginInterface): Observable<LoginResponseInterface> {
    return HttpFactory.POST(apiRoutes.login, data);
  }
}

export class ProxyService {
  static proxy(method: 'GET' | 'POST', url: string, payload?: any): Observable<LoginResponseInterface> {
    return HttpFactory.POST(apiRoutes.proxy, { method, url, payload });
  }
}
