import React from 'react';
import * as serviceWorker from './serviceWorker';
import * as momentTimezone from 'moment-timezone';
import App from './app';
import ReactDOM from 'react-dom/client';

import './assets/scss/_index.scss';
import './assets/images/index';
import './assets/antd/antd.css';


momentTimezone.locale('nl');
momentTimezone.tz.setDefault('Europe/Zagreb');
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container! as HTMLElement);
const app = <App />;
root.render(app);

serviceWorker.unregister();
