import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Card, Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { VideoImg } from '../../../assets/images';
import { getCampagnes, i18n, useAppDispatch, useAppSelector } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import './helpPage.style.scss';
import { Link } from 'react-router-dom';

const { Search } = Input;

function HelpPageComponent() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCampagnes());
  }, []);

  const campagnes = useAppSelector((state) => state.campagnes.data);

  const [cardsSelected, setCardsSelected] = useState<any>([]);

  useEffect(() => {
    setCardsSelected(
      campagnes.map((c: any) => {
        return { id: c.id, selected: false };
      })
    );
  }, campagnes);

  const onSearch = (value: string) => console.log(value);

  // Render

  const renderBreadcrumb = (): React.ReactNode => {
    return (<Breadcrumb 
      items={[
        {
          title: <Link to={AppRoutes.DASHBOARD.fullPath}><HomeOutlined /></Link>,
        },
        {
          title: <Link to={AppRoutes.HELP.fullPath}>{i18n.translate('helpPage.breadcrumb')}</Link>,
        },
      ]}
    />);
  };

  const renderCard = (): React.ReactNode => {
    return (
      <>
        <div className="help-card">
          <div>{i18n.translate('helpPage.card.general')}</div>
          <div>{i18n.translate('helpPage.card.campaigns')}</div>
          <div>{i18n.translate('helpPage.card.pim')}</div>
          <div>{i18n.translate('helpPage.card.users')}</div>
        </div>
        
        <div>
          <div style={{ marginBottom: '32px', fontSize: 24 }}>{i18n.translate('helpPage.general')}</div>
          <div className="help-card-section">
            <div>
              <img src={VideoImg} alt="Omnyo" title="Omnyo" style={{ width: '100%' }} />
              <div>{i18n.translate('helpPage.dashboard')}</div>
            </div>
            <div>
              <img src={VideoImg} alt="Omnyo" title="Omnyo" style={{ width: '100%' }} />
              <div>Lorem ipsum dolor sit amet conductur facit</div>
            </div>
          </div>

          <div style={{ marginBlock: '32px', fontSize: 24 }}>{i18n.translate('helpPage.campaigns')}</div>
          <div className="help-card-section">
            <div>
              <img src={VideoImg} alt="Omnyo" title="Omnyo" style={{ width: '100%' }} />
              <div>{i18n.translate('helpPage.campaignNew')}</div>
            </div>
            <div>
              <img src={VideoImg} alt="Omnyo" title="Omnyo" style={{ width: '100%' }} />
              <div>{i18n.translate('helpPage.campaignEdit')}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderBreadcrumb()}
      <div style={{ margin: 80 }}>
        <Row justify={'end'} style={{ marginBottom: 24 }}>
          <Col span={10}>
            <Search placeholder={i18n.translate('helpPage.search')} allowClear onSearch={onSearch} enterButton />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="dashboard cards-scroll">{renderCard()}</Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default HelpPageComponent;
