import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Row, Tabs, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { colors } from '../../../app';
import { NavigationService, getCampagnes, getPublications, i18n, useAppDispatch, useAppSelector } from '../../../common';
import { BarDiagram } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const { Text } = Typography;

function CampagnePageComponent() {
  const dispatch = useAppDispatch();

  const params = useParams<{ campagneId?: string }>();
  const campagneId = parseInt(params.campagneId!);

  const campagne = useAppSelector((state) => state.campagnes.data.find((c: any) => c.id == campagneId));
  const publications = useAppSelector((state) => state.campagnes.publications[campagneId]);

  const campagnes = useAppSelector((state) => state.campagnes.data);
  useEffect(() => {
    dispatch(getCampagnes());
    dispatch(getPublications(campagneId));
  }, []);

  // RENDER

  const renderBreadcrumb = (): React.ReactNode => {
    return (<Breadcrumb 
      items={[
        {
          title: <Link to={AppRoutes.DASHBOARD.fullPath}><HomeOutlined /></Link>,
        },
        {
          title: <Link to={AppRoutes.CAMPAGNES.fullPath}>{i18n.translate('campaigns.breadcrumb')}</Link>,
        },
        {
          title: <Link to={`${AppRoutes.CAMPAGNES.fullPath}/${campagne?.id}`}>{campagne?.name}</Link>,
        },
      ]}
    />);
  };

  const renderStats = (): React.ReactNode => {
    const dataPoints: any = [
      {
        name: 'Product 1',
        value: 6,
        color: '#0D0584',
      },
      {
        name: 'Product 2',
        value: 5,
        color: '#554FB4',
      },
      {
        name: 'Product 3',
        value: 9,
        color: '#8580C7',
      },
      {
        name: 'Product 4',
        value: 3,
        color: '#AAA6DA',
      },
      {
        name: 'Product 5',
        value: 2,
        color: '#C4C1E8',
      },
    ];
    return (
      <>
        <Row style={{ marginBottom: 24 }} gutter={16}>
          <Col span={12}>
            <Text style={{ marginTop: 0 }}>{i18n.translate('campaigns.top5')}</Text>
            <BarDiagram dataPoints={dataPoints} maxValue={10}></BarDiagram>
          </Col>
          <Col span={12}>
            <Text style={{ marginTop: 0 }}>{i18n.translate('campaigns.top5')}</Text>
            <BarDiagram dataPoints={dataPoints} maxValue={10}></BarDiagram>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Text style={{ marginTop: 0 }}>{i18n.translate('campaigns.top5')}</Text>
            <BarDiagram dataPoints={dataPoints} maxValue={10}></BarDiagram>
          </Col>
        </Row>
      </>
    );
  };

  const renderGeneral = (): React.ReactNode => {
    return (
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 24 }} gutter={16}>
            <Col span={24}>
              <Card className="box-shadow">{renderStats()}</Card>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Card
                className="box-shadow"
                style={{
                  height: 100,
                  borderBottomWidth: 10,
                  borderBottomStyle: 'solid',
                  borderBottomColor: colors.yellow,
                }}
              >
                <Text style={{ marginTop: 0 }}>{i18n.translate('campaigns.products')}</Text>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="box-shadow"
                style={{
                  height: 100,
                  borderBottomWidth: 10,
                  borderBottomStyle: 'solid',
                  borderBottomColor: colors.yellow,
                }}
              >
                <Text style={{ marginTop: 0 }}>{i18n.translate('campaigns.promos')}</Text>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="box-shadow"
                style={{
                  height: 100,
                  borderBottomWidth: 10,
                  borderBottomStyle: 'solid',
                  borderBottomColor: colors.yellow,
                }}
              >
                <Text style={{ marginTop: 0 }}>{i18n.translate('campaigns.priceCards')}</Text>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="box-shadow"
                style={{
                  height: 100,
                  borderBottomWidth: 10,
                  borderBottomStyle: 'solid',
                  borderBottomColor: colors.yellow,
                }}
              >
                <Text style={{ marginTop: 0 }}>{i18n.translate('campaigns.onlineMaterial')}</Text>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderPublicaties = (): React.ReactNode => {
    const renderCard = (campagne: any, index: number): React.ReactNode => {
      return (
        <div className="card" key={index}>
          <div className="card-img">
            <Button className="card-img-btn">{i18n.translate('campaigns.view')}</Button>
          </div>
          <div className="card-title">{campagne.name}</div>
        </div>
      );
    };

    if (!publications) return null;

    return (
      <Card className="box-shadow">
        <div className="cards-camp">{publications.map((campagne: any, index) => renderCard(campagne, index))}</div>
      </Card>
    );
  };

  const tabItems: any = [
    {
      label: i18n.translate('campaigns.statistics'),
      key: '1',
      children: renderGeneral(),
    },
    {
      label: i18n.translate('campaigns.publications'),
      key: '2',
      children: renderPublicaties(),
    },
  ];

  return (
    <>
      <Row justify={'space-between'}>
        <Col>{renderBreadcrumb()}</Col>
        <Col>
          <Button>{i18n.translate('campaigns.export')}</Button>
        </Col>
      </Row>
      <div style={{ paddingTop: 32, paddingInline: 80 }}>
        <Tabs defaultActiveKey="1" items={tabItems} />
      </div>
    </>
  );
}

export default CampagnePageComponent;
