import { Button, Checkbox, Form, Input, Typography } from 'antd';
import React from 'react';
import { i18n } from '../../common';
import { AuthRoutes } from '../../features/auth';
import './loginForm.style.scss';
const { Title, Link } = Typography;

interface Props {
  onLoginFormFinish?: (values: any) => void;
  onLoginFormFinishFailed?: (errorInfo: any) => void;
}

function LoginFormComponent({ onLoginFormFinish, onLoginFormFinishFailed }: Props) {
  return (
    <>
      <Title level={2}>{i18n.translate(`login.title`)}</Title>
      <Form
        layout="vertical"
        name="login"
        initialValues={{ remember: true, username: '', password: '' }}
        onFinish={onLoginFormFinish}
        onFinishFailed={onLoginFormFinishFailed}
      >
        <Form.Item
          label={i18n.translate(`login.form.username`)}
          name="username"
          rules={[{ required: true, message: i18n.translate(`login.msgs.required`) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div>{i18n.translate(`login.form.password`)}</div>
              {/* <Link href={AuthRoutes.RESET_PASSWORD.fullPath} style={{ textAlign: 'right' }}>
                {i18n.translate('login.forgotPassword')}
              </Link> */}
            </div>
          }
          name="password"
          rules={[{ required: true, message: i18n.translate(`login.msgs.required`) }]}
          className="form-label-flex"
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>{i18n.translate(`login.form.remember`)}</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit">{i18n.translate(`login.btns.login`)}</Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default LoginFormComponent;
