import { Card } from 'antd';
import React from 'react';
import { Background01 } from '../../../assets/images';
import { LoginInterface, loginAction, useAppDispatch } from '../../../common';
import { LoginFormComponent } from '../../../components';
import LayoutFlexWithLogo from '../../../components/layout/layoutFlexWithLogo.component';

function LoginPageComponent() {
  const dispatch = useAppDispatch();

  const onLogin = (values: any) => {
    let loginData: LoginInterface = {
      db: 'etosdata',
      email: values.username,
      password: values.password,
      remember: values.remember
    };

    dispatch(loginAction(loginData));
  };

  const onLoginFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <LayoutFlexWithLogo backgroundImageUrl={Background01}>
      <Card style={{ width: 800, margin: '0 auto', padding: 100 }} className="login box-shadow">
        <LoginFormComponent
          onLoginFormFinish={(values) => onLogin(values)}
          onLoginFormFinishFailed={(errorInfo) => onLoginFailed(errorInfo)}
        />
      </Card>
    </LayoutFlexWithLogo>
  );
}

export default LoginPageComponent;
