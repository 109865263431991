import React from 'react';
import './barDiagram.style.scss';

interface Props {
  dataPoints: Array<DataPoint>;
  maxValue: number;
  defaultColor?: string;
}

interface DataPoint {
  name?: string;
  value: number;
  color?: string;
}

export default function BarDiagram(props: Props) {
  const { dataPoints, maxValue, defaultColor = '#000000' } = props;

  return (
    <div className="chart-wrapper">
      <ul className="chart" id="chart">
        {dataPoints.map((dataPoint: DataPoint, i: number) => {
          return (
            <li
              key={i}
              style={{
                gridRow: `${maxValue - dataPoint.value + 1} / span ${dataPoint.value}`,
                gridColumn: `${i + 1}`,
                backgroundColor: `${dataPoint.color ? dataPoint.color : defaultColor}`,
              }}
            >
              <span>{dataPoint.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
