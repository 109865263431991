import React from 'react';

import { LogoImg, LogoShortImg } from '../../assets/images';

export default function Logo(props: { href?: string; className?: string; version?: 'full' | 'short' }) {
  const { href, className = '', version = 'full' } = props;
  const logoSrc = version == 'full' ? LogoImg : LogoShortImg;
  return (
    <a className={className} href={href}>
      <img src={logoSrc} alt="Omnyo" title="Omnyo" />
    </a>
  );
}
