import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { AppDispatch } from './store';
import { ProxyService } from '../api';

// ============ INTERFACE ============

interface PublicationsCollectionInterface {
  [code: number]: Array<{ id: number; name: string }>;
}

export interface CampagnesStateInterface {
  data: Array<{ id: number; name: string }>;
  publications: PublicationsCollectionInterface;
  error?: string;
}

// ============ INIT STATE ============

const initialState: CampagnesStateInterface = {
  data: [],
  publications: {}
};

// ========= SLICE ==========

export const campagnesSlice = createSlice({
  name: 'campagnes',
  initialState: initialState,
  reducers: {
    setCampagnes: (state: CampagnesStateInterface, action: PayloadAction<any>) => {
      state.data = action.payload.map((x: any) => ({ ...x, name: x.publicationName, id: x.publicationId }))
    },
    setPublications: (state: CampagnesStateInterface, action: PayloadAction<any>) => {
      state.publications[action.payload.publicationId] = action.payload.data.map((x: any) => ({ ...x, name: x.description, id: x.editionId }))
    }
  },
});

export const { setCampagnes, setPublications } = campagnesSlice.actions;

export const getCampagnes = () => {
  return (dispatch: AppDispatch) => {
    return ProxyService.proxy('GET', 'https://api.pubble.cloud/api/etosdata/v3/publications').subscribe({
      next: (response: any) => {
        dispatch(setCampagnes(response));
      },
      error: (error: Error) => {},
    });
  };
};

export const getPublications = (id: number) => {
  return (dispatch: AppDispatch) => {
    return ProxyService.proxy('GET', 'https://api.pubble.cloud/api/etosdata/v3/editions?publicationId=' + id).subscribe({
      next: (response: any) => {
        dispatch(setPublications({
          publicationId: id,
          data: response
        }));
      },
      error: (error: Error) => {},
    });
  };
};

export default campagnesSlice.reducer;
