import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { NotFoundPageComponent } from '../../../common';
import CampagnePageComponent from '../campagne/campagnePage.component';
import CampagnesPageComponent from '../campagnes/campagnesPage.component';
import DashboardPageComponent from '../dashboard/dashboardPage.component';
import HelpPageComponent from '../help/helpPage.component';
import { AppRoutes } from './app.routes';

import { Background02 } from '../../../assets/images';
import { LayoutWithSider } from '../../../components';

function AppRouter() {
  // const userAuth = useSelector((state: RootState) => state.auth);
  
  const { path } = useRouteMatch();

  let routes = [
    {
      path: AppRoutes.DASHBOARD.path,
      component: DashboardPageComponent,
      roles: [],
      exact: true,
    },
    {
      path: AppRoutes.CAMPAGNES.path,
      component: CampagnesPageComponent,
      roles: [],
      exact: true,
    },
    {
      path: AppRoutes.CAMPAGNES.path + '/:campagneId',
      component: CampagnePageComponent,
      roles: [],
      exact: true,
    },
    {
      path: AppRoutes.HELP.path,
      component: HelpPageComponent,
      roles: [],
      exact: true,
    },
  ];

  const allowedRoutes = routes.filter((r) => {
    // if (r.roles.length === 0) return true;
    // if (!userAuth.isAuthenticated || !userAuth.user) return false;
    // if (r.roles.includes(userAuth.user.role)) return true;

    return true;
  });

  return (
    <LayoutWithSider backgroundImageUrl={Background02}>
      <Switch>
        {allowedRoutes.map((route, index) => (
          <Route exact={route.exact} path={`${path}/${route.path}`} key={'link-'+index}>
            {' '}
            <route.component />{' '}
          </Route>
        ))}
        <Route component={NotFoundPageComponent} />
      </Switch>
    </LayoutWithSider>
  );
}

export default AppRouter;
