import { Card } from 'antd';
import React from 'react';
import { Background01 } from '../../../assets/images';
import { LoginInterface, NavigationService, useAppDispatch } from '../../../common';
import { ResetPasswordFormComponent } from '../../../components';
import LayoutFlexWithLogo from '../../../components/layout/layoutFlexWithLogo.component';
import { AuthRoutes } from '../_router/auth.routes';

function ResetPasswordPage() {
  const dispatch = useAppDispatch();

  const onResetPassword = (values: any) => {
    console.log('here');
    let loginData: LoginInterface = {
      db: '',
      email: values.username,
      password: values.password,
    };
    //dispatch(loginAction(loginData));
    NavigationService.navigate(AuthRoutes.LOGIN.fullPath);
  };

  const onResetPasswordFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <LayoutFlexWithLogo backgroundImageUrl={Background01}>
      <Card style={{ width: 800, margin: '0 auto', padding: 100 }} className="login box-shadow">
        <ResetPasswordFormComponent
          onResetPasswordFormFinish={(values) => onResetPassword(values)}
          onResetPasswordFormFinishFailed={(errorInfo) => onResetPasswordFailed(errorInfo)}
        ></ResetPasswordFormComponent>
      </Card>
    </LayoutFlexWithLogo>
  );
}

export default ResetPasswordPage;
