import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth.slice';
import campagnesReducer from './campagnes.slice';

// === List of all redducers
const rootReducers = combineReducers({
  auth: authReducer,
  campagnes: campagnesReducer,
});

// export const AppReducers = (state: any, action: any): RootState => {
//   return AllReducers(state, action);
// };

// === LocalSotrage persist definition
const persistConfig = {
  key: 'my-app',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

//const initialState = {};

//const middleware = [thunk];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
    })   
  })
  // persistedReducer,
  // initialState,
  // composeWithDevTools(applyMiddleware(...middleware))
  //compose(applyMiddleware(...middleware))


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

//export type RootState = ReturnType<typeof AllReducers>;
