import {
  LeftOutlined,
  NotificationOutlined,
  PieChartOutlined,
  PoweroffOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  RightOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { Layout, Menu, notification, theme } from 'antd';
import React, { useState } from 'react';
import { i18n, logoutAction, useAppDispatch } from '../../common';
import { AppRoutes } from '../../features/app';
import Logo from '../logo/logo.component';
import './layoutWithSider.style.scss';
import { Link } from 'react-router-dom';

const { Sider, Content } = Layout;

export default function LayoutWithSider(props: {
  children: React.ReactNode;
  className?: string;
  backgroundImageUrl: string;
}) {
  const { children, className, backgroundImageUrl } = props;
  const {
    token: { colorPrimary },
  } = theme.useToken();
  
  const dispatch = useAppDispatch();

  const [collapsed, setCollapsed] = useState(false);

  const menuItems = [
    {
      key: 'dashboard',
      icon: <PieChartOutlined />,
      label: <Link to={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('sider.dashboard')}</Link>,
    },
    {
      key: 'campaigns',
      icon: <NotificationOutlined />,
      label: <Link to={AppRoutes.CAMPAGNES.fullPath}>{i18n.translate('sider.campaigns')}</Link>,
    },
    {
      key: 'pim',
      icon: <ProfileOutlined />,
      style: { opacity: 0.3 },
      label: i18n.translate('sider.pim'),
    },
    {
      key: 'users',
      icon: <TeamOutlined />,
      style: { opacity: 0.3 },
      label: i18n.translate('sider.users'),
    },
    {
      key: 'sider',
      icon: <QuestionCircleOutlined />,
      style: { opacity: 0.3 },
      label: i18n.translate('sider.help'),
      // label: <Link to={AppRoutes.HELP.fullPath}>{i18n.translate('sider.help')}</Link>,
    },
  ];

  function onMenuItemClick(params: { key: string }) {
    let { key } = params;
    switch (key) {
      case 'pim': return notification['info']({ message: 'pim - Currently not supported', duration: 2 });
      case 'users': return notification['info']({ message: 'users - Currently not supported', duration: 2 });
      case 'sider': return notification['info']({ message: 'sider - Currently not supported', duration: 2 });
      case 'logout': return dispatch(logoutAction());
    }
  }

  return (
    <Layout
      className={`layout-background ${className ? className : ''}`}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={250}
        className="sider"
        style={{ backgroundColor: colorPrimary, display: 'flex', flexDirection: 'column' }}
      >
        {!collapsed && <Logo className="logo-sider" />}
        {collapsed && <Logo version="short" className="logo-sider" />}
        <Menu onClick={onMenuItemClick} style={{ marginTop: 'auto' }} items={menuItems} />
        <Menu
          onClick={onMenuItemClick}
          items={[
            {
              key: 'logout',
              icon: <PoweroffOutlined />,
              label: <>{i18n.translate('sider.logout')}</>,
            },
          ]}
        />
        <div className="trigger-wrapper">
          {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
        </div>
      </Sider>
      <Content style={{ paddingBlock: 48, paddingInline: 48, height: '100%', overflowY: 'auto' }}>{children}</Content>
    </Layout>
  );
}
