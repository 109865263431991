import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AppRoutes } from '../../features/app';
import { AuthRoutes } from '../../features/auth';
import { UserService } from '../api';
import { LoginInterface, LoginResponseInterface, UserInterface } from '../interfaces';
import { i18n, NavigationService } from '../services';
import { LangType } from '../types';
import type { AppDispatch } from './store';

// ============ INTERFACE ============

export interface AuthStateInterface {
  lang: LangType;
  isAuthenticated: boolean;
  accessToken?: string;
  user?: UserInterface;
  error?: string;
}

// ============ INIT STATE ============

const initialState: AuthStateInterface = {
  lang: 'nl',
  isAuthenticated: false,
};

// ========= SLICE ==========

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginSuccess: (state: AuthStateInterface, action: PayloadAction<LoginResponseInterface>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.data.accessToken;
      state.user = action.payload.data.user;
    },
    loginError: (state: AuthStateInterface, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setLangAction: (state: AuthStateInterface, action: PayloadAction<LangType>) => {
      state.lang = action.payload;
    },
    logoutSuccess: (state: AuthStateInterface) => {
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.user = undefined;
    },
  },
});

export const { loginSuccess, loginError, logoutSuccess, setLangAction } = authSlice.actions;

export const loginAction = (data: LoginInterface) => {
  return (dispatch: AppDispatch) => {
    return UserService.login(data).subscribe({
      next: (response: LoginResponseInterface) => {
        dispatch(loginSuccess(response));
        notification['success']({ message: i18n.translate('login.msgs.login'), duration: 2 });
        NavigationService.navigate(AppRoutes.DASHBOARD.fullPath);
      },
      error: (error: Error) => {
        notification['error']({ message: i18n.translate('login.msgs.error'), duration: 2 });
        dispatch(loginError(error.toString()));
      },
    });
  };
};

export const logoutAction = () => {
  return (dispatch: AppDispatch) => {
    dispatch(logoutSuccess());
    notification['success']({ message: i18n.translate('login.msgs.logout'), duration: 2 });
    NavigationService.navigate(AuthRoutes.LOGIN.fullPath);
  };
};

export default authSlice.reducer;
