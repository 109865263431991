import {
  CloseOutlined, CopyOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, FolderOutlined, HomeOutlined
} from '@ant-design/icons';
import { Breadcrumb, Card, Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { getCampagnes, i18n, NavigationService, useAppDispatch, useAppSelector } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import { Link } from 'react-router-dom';

const { Search } = Input;

function CampagnesPageComponent() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCampagnes());
  }, []);

  const campagnes = useAppSelector((state) => state.campagnes.data);

  const [cardsSelected, setCardsSelected] = useState<any>([]);

  useEffect(() => {
    setCardsSelected(
      campagnes.map((c: any) => {
        return { id: c.id, selected: false };
      })
    );
  }, [campagnes]);

  const onSearch = (value: string) => console.log(value);

  // Render

  const renderBreadcrumb = (): React.ReactNode => {
    return (<Breadcrumb 
      items={[
        {
          title: <Link to={AppRoutes.DASHBOARD.fullPath}><HomeOutlined /></Link>,
        },
        {
          title: <Link to={AppRoutes.CAMPAGNES.fullPath}>{i18n.translate('campaigns.breadcrumb')}</Link>,
        },
      ]}
    />);
  };

  const renderCampagnesCards = (): React.ReactNode => {
    const onCardSelect = (id: number) => {
      setCardsSelected(
        cardsSelected.map((c: any) => {
          if (c.id == id) return { id: c.id, selected: true };
          else return { id: c.id, selected: false };
        })
      );
    };

    const onCardUnselect = (id: number) => {
      setCardsSelected(
        cardsSelected.map((c: any) => {
          return { id: c.id, selected: false };
        })
      );
    };
    const renderCampagneCard = (campagne: any): React.ReactNode => {
      const icon: React.ReactNode = cardsSelected.find((c: any) => c.id == campagne.id)?.selected ? (
        <div className="card-select" onClick={() => onCardUnselect(campagne.id)}>
          <CloseOutlined />
        </div>
      ) : (
        <div className="card-select" onClick={() => onCardSelect(campagne.id)}>
          <EllipsisOutlined />
        </div>
      );
      return (
        <div
          className={`card-camp ${cardsSelected.find((c: any) => c.id == campagne.id)?.selected ? 'selected' : ''}`}
          key={campagne.id}
        >
          <div className="card-img">
            <div className="card-img-menu">
              <div
                className="card-img-menu-item"
                onClick={() => NavigationService.navigate(`${AppRoutes.CAMPAGNES.fullPath}/${campagne.id}`)}
              >
                <EditOutlined />
                <span>{i18n.translate('campaigns.actions.changeName')}</span>
              </div>
              <div className="card-img-menu-item">
                <CopyOutlined />
                <span>{i18n.translate('campaigns.actions.copy')}</span>
              </div>
              <div className="card-img-menu-item">
                <FolderOutlined />
                <span>{i18n.translate('campaigns.actions.archive')}</span>
              </div>
              <div className="card-img-menu-item">
                <DeleteOutlined />
                <span>{i18n.translate('campaigns.actions.delete')}</span>
              </div>
            </div>
          </div>
          <div onClick={() => NavigationService.navigate(`${AppRoutes.CAMPAGNES.fullPath}/${campagne.id}`)} className="card-title">
            {campagne.name}
          </div>
          {icon}
        </div>
      );
    };

    return <div className="cards-camp">{campagnes.map((campagne: any) => renderCampagneCard(campagne))}</div>;
  };

  return (
    <>
      {renderBreadcrumb()}
      <div style={{ margin: 80 }}>
        <Row justify={'end'} style={{ marginBottom: 24 }}>
          <Col span={10}>
            <Search placeholder={i18n.translate('campaigns.search')} allowClear onSearch={onSearch} enterButton />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="box-shadow cards-scroll">{renderCampagnesCards()}</Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CampagnesPageComponent;
