import { Layout } from 'antd';
import React from 'react';
import Logo from '../logo/logo.component';
import './layoutFlexWithLogo.style.scss';

export default function LayoutFlexWithLogo(props: {
  children: React.ReactNode;
  className?: string;
  backgroundImageUrl: string;
}) {
  const { children, className, backgroundImageUrl } = props;

  return (
    <Layout
      className={`layout-flex layout-background ${className ? className : ''}`}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      <Logo className="logo-fixed" />
      {children}
    </Layout>
  );
}
