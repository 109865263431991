import { Button, Form, Input } from 'antd';
import React from 'react';
import { i18n, loginAction, LoginInterface, useAppDispatch } from '../../common';

interface Props {
  onResetPasswordFormFinish?: (values: any) => void;
  onResetPasswordFormFinishFailed?: (errorInfo: any) => void;
}

function ResetPasswordFormComponent({ onResetPasswordFormFinish, onResetPasswordFormFinishFailed }: Props) {

  return (
    <>
      <Form
        layout="vertical"
        name="resetPassword"
        onFinish={onResetPasswordFormFinish}
        onFinishFailed={onResetPasswordFormFinishFailed}
      >
        <Form.Item
          label={i18n.translate(`login.form.mail`)}
          name="mail"
          rules={[{ required: false, message: i18n.translate(`login.msgs.required`) }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit">{i18n.translate(`login.btns.resetPassword`)}</Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default ResetPasswordFormComponent;
